#page-footer {
  background-color: #000000c4;
  justify-content: center;
  padding: 64px 16px;
  display: flex;
}

#contract-addr-cont {
  color: #fff;
  word-break: break-all;
  text-align: center;
  font-family: monospace;
  font-size: 16px;
}

#contract-addr-cont a {
  color: #fff;
}

/*# sourceMappingURL=index.4107e6e4.css.map */
