@import "/src/constants";

#page-footer {
  display: flex;
  justify-content: center;
  padding: 64px 16px;
  background-color: rgba(0, 0, 0, 0.77);
}

#contract-addr-cont {
  font-family: monospace;
  color: white;
  font-size: 16px;
  word-break: break-all;
  text-align: center;

  a {
    color: white;
  }
}
